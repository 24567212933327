/**
 * This file serve as the JavaScript entrypoint to all web pages in Portal.
 * Use this file to load any non-React libraries or (if truly necessary) add
 * global functions.
 */

// Include the JavaScript to format and convert UTC timestamps to local
require("./datetime-to-local");

// Include the JavaScript to copy to clipboard
require("./copy-to-clipboard");

// Import the JavaScript functionality for the Bootstrap front-end library
import "bootstrap";

require("./offboard-banner"); // must be after bootstrap

// Needed so we can support async functions.
import "regenerator-runtime/runtime";

import Cookies from "js-cookie";

// Add tooltips, if present
$('[data-toggle="tooltip"]').tooltip();

// Add Google Tag Manager for all pages
// See https://developers.google.com/tag-manager/quickstart
(function (w, d, s, l, i) {
  w[l] = w[l] || [];
  w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
  var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l != "dataLayer" ? "&l=" + l : "";
  j.async = true;
  j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
  f.parentNode.insertBefore(j, f);
})(window, document, "script", "dataLayer", "GTM-MX5544L");

// Horizontally collapse sidebar when collapse button is clicked
$("[data-toggle=sidebar-collapse]").on("click", function () {
  toggleSidebarCollapse();
});
$("[data-toggle=sidebar-collapse]").on("keypress", function () {
  toggleSidebarCollapse();
});

function toggleSidebarCollapse() {
  $(".sidebar__container").toggleClass("sidebar-collapsed"); // toggle sidebar width
  $(".header__container--side").toggleClass("sidebar-collapsed"); // toggle navbar brand width

  if (Cookies.get("collapseLeftNav")) {
    Cookies.remove("collapseLeftNav");
  } else {
    Cookies.set("collapseLeftNav", true);
  }
}

$(function () {
  // Sometimes the sidebar contents take up more than the viewport height, so we need
  // to set a max-height. This is just the height of the contents of the left nav,
  // which we calculate by getting the position of the last nav item plus its height.
  var lastItem = $(".sidebar-item-list").children().slice(-1)[0];
  lastItem = $(lastItem);
  var sidebarHeight = lastItem.position().top + lastItem.height();
  $(".wrapper").css("max-height", sidebarHeight);
});
