import axios from "axios";
import Cookies from "js-cookie";

function sendAuditEvent(auditUrl, auditPayload) {
  axios.post(auditUrl, auditPayload, {
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": Cookies.get("csrftoken") || "",
    },
  });
}

function copyToClipboard() {
  let copyButtons = [...document.getElementsByClassName("copy-btn")];
  copyButtons.forEach((btn) =>
    btn.addEventListener("click", () => {
      const data_text = btn.getAttribute("data-text");
      const gcp_project_id = btn.getAttribute("id");
      const data_action = btn.getAttribute("data-action");
      const auditUrl = btn.getAttribute("data-url")
        ? btn.getAttribute("data-url")
        : `${window.location.href}${gcp_project_id}/`;
      const auditPayload = { data_text, gcp_project_id, data_action };
      navigator.clipboard.writeText(data_text);
      sendAuditEvent(auditUrl, auditPayload);
      navigator.clipboard.readText().then(() => {
        btn.textContent = `Copied to clipboard`;
      });
    })
  );
}

copyToClipboard();
