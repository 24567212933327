import Cookies from "js-cookie";
import moment from "moment-timezone";

// Must match Django template
const cookieName = "hideOffboardBanner";

const setSnooze = (ev: JQuery.TriggeredEvent) => {
  const expireAt = moment().startOf("day");
  if (ev.target.textContent === "1 day") {
    expireAt.add(1, "day");
  } else if (ev.target.textContent === "1 week") {
    expireAt.add(1, "week");
  } else if (ev.target.textContent === "2 weeks") {
    expireAt.add(2, "week");
  } else {
    expireAt.add(1, "year");
  }
  Cookies.set(cookieName, "true", { expires: expireAt.toDate() });
};

$("#offboard-banner-snooze-options button").on("click", setSnooze);
