import moment from "moment-timezone";

/**
 * Convert a date string in UTC to a client-local time in our desired format.
 *
 * @param {string} dateString - The timestamp in ISO8601 format
 *    ("2018-11-09T10:00:00")
 */
function convertDatetimeToLocal(dateString) {
  const timeFormat = " [at] h:mm A";
  const longFormat = `MMMM D, YYYY${timeFormat}`;
  const date = moment.tz(dateString.trim(), moment.tz.guess());
  if (date.isValid()) {
    return date.format(longFormat);
  }
  return dateString;
}

function convertDatetimeToDateOnly(dateString) {
  const longFormat = `MMMM D, YYYY`;
  const date = moment(dateString.trim());
  if (date.isValid()) {
    return date.format(longFormat);
  }
  return dateString;
}

function convertAllDates() {
  // Convert all elements with the matching classnames to the desired datetime format
  const datetimeNodes = document.querySelectorAll(".js-datetime-to-local");
  const datetimeTzNodes = document.querySelectorAll(".js-datetime-to-tz");
  datetimeTzNodes.forEach((node) => {
    // Check for the CSS class that indicates whether to display the timestamp
    // in a more condensed format
    if (node.matches("input")) {
      const inputDate = node.getAttribute("placeholder");
      node.setAttribute("placeholder", convertDatetimeToDateOnly(inputDate));
    } else {
      // Assume something with text nodes
      node.textContent = convertDatetimeToDateOnly(node.textContent);
    }
    node.style.visibility = "visible";
  });

  datetimeNodes.forEach((node) => {
    // Check for the CSS class that indicates whether to display the timestamp
    // in a more condensed format
    if (node.matches("input")) {
      const inputDate = node.getAttribute("placeholder");
      node.setAttribute("placeholder", convertDatetimeToLocal(inputDate));
    } else {
      // Assume something with text nodes
      node.textContent = convertDatetimeToLocal(node.textContent);
    }
    node.style.visibility = "visible";
  });
}

convertAllDates();
